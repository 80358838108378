import { BookmarkProvider } from "./Bookmark";
import { PreferenceProvider } from "./Preference";

const ContextProviders = ({ children }) => {
  return (
    <BookmarkProvider>
      <PreferenceProvider>
        {children}
      </PreferenceProvider>
    </BookmarkProvider>
  );
};

export default ContextProviders;
